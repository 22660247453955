import ContactForm from "../components/ContactForm";

const Contact = () => {
    return (
        <div>
            <h1>Contact</h1>
            <ContactForm/>
        </div>
    )
}

export default Contact;