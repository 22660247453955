//styles
import styles from '../styles/Footer.module.css'

function Footer() {
  return (
    <div className={styles.Footer}>
      <h2></h2>
    </div>
  );
}

export default Footer;
